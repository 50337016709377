import { Config } from '@/types/config'
import { defaultConfiguration } from './default-configuration'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
dayjs.extend(relativeTime)
dayjs.extend(utc)

const deviceLengthList = {
  mobile: 10,
  tablet: 20,
  desktop: 30,
  widescreen: 40,
  fullhd: 50,
}

type Device = 'mobile' | 'tablet' | 'desktop' | 'widescreen' | 'fullhd'

const checkLengthAndSubstring = (text: string, type: Device) => {
  const length = text.length
  const deviceLength = deviceLengthList[type]
  if (length > deviceLength) {
    return text.substring(0, deviceLength) + '...'
  }
  return text
}
export const cryptoList = [
  { type: 'Hash', list: ['MD5', 'SHA1', 'SHA256', 'SHA3', 'SHA512'] },
  {
    type: 'Hmac',
    list: ['HmacMD5', 'HmacSHA1', 'HmacSHA256', 'HmacSHA512'],
  },
  { type: 'PBKDF2', list: ['PBKDF2'] },
  { type: 'Ciphers', list: ['AES', 'DES', 'TripleDES', 'Rabbit'] },
  {
    type: 'Encoders',
    list: ['Base64', 'Latin1', 'Hex', 'Utf8', 'Utf16', 'Utf16LE'],
  },
]

export const defaultConfig: Config = defaultConfiguration

export const truncateTextByScreenSize = (text: string) => {
  const width = window.innerWidth
  if (width < 769) {
    text = checkLengthAndSubstring(text, 'mobile')
  } else if (width < 1024) {
    text = checkLengthAndSubstring(text, 'tablet')
  } else if (width < 1216) {
    text = checkLengthAndSubstring(text, 'desktop')
  } else if (width < 1408) {
    text = checkLengthAndSubstring(text, 'widescreen')
  } else {
    text = checkLengthAndSubstring(text, 'fullhd')
  }
  return text
}

export const cryptoListName = (): string[] => {
  let lists: string[] = []
  cryptoList.forEach((crypto) => {
    lists = lists.concat(crypto.list)
  })
  return lists
}

export const createdAtFromNow = (createdAt: number) => {
  return dayjs().to(dayjs.unix(createdAt))
}

export const getStartAndEndDateBy = (type: any) => {
  const startDate = dayjs().startOf(type).unix()
  const endDate = dayjs().endOf(type).unix()
  return {
    startDate,
    endDate,
  }
}

export const getListDaysInMonth = () => {
  const numberOfDays = dayjs().daysInMonth()
  const startDate = dayjs().startOf('month')
  const listDays = Array(numberOfDays)
    .fill('')
    .map((_, index) => {
      const date = startDate.add(index, 'day').format('YYYY-MM-DD')
      return date
    })
  return listDays
}

export const formatDate = (date: number | string, formatType: string) => {
  if (typeof date === 'number') {
    return dayjs.unix(date).format(formatType)
  } else {
    return dayjs(date).format(formatType)
  }
}
