import { Config } from '@/types/config'

export const defaultConfiguration: Config = {
  components: {
    crypto: {
      ciphers: {
        modesBox: {
          label: 'Modes',
        },
        paddingBox: {
          label: 'Padding Schemes',
        },
        secretBox: {
          label: 'Secret',
          placeholder: 'Secret Passphrase',
        },
      },
      hash: {
        outputLengthBox: {
          label: 'Output Length',
        },
      },
      hmac: {
        secretBox: {
          label: 'Secret',
          placeholder: 'Secret Passphrase',
        },
      },
      pbkdf2: {
        iterationsBox: {
          label: 'Iterations',
          placeholder: 'Iterations',
        },
        keysizeBox: {
          label: 'Key Size',
        },
        saltBox: {
          label: 'Salt',
          placeholder: 'Salt',
        },
      },
    },
    general: {
      tabs: {
        decrypt: {
          buttons: {
            copy: {
              color: 'primary',
              copiedTitle: 'Copied',
              title: 'Copy',
            },
            submit: {
              color: 'link',
              title: 'Submit',
            },
          },
          messageBox: {
            label: 'Encrypted Text',
            placeholder: 'Encrypted Text',
          },
          title: 'Decrypt',
          result: {
            label: 'Result',
            placeholder: 'Result',
            noResult: `Sorry, this encrypted text isn't stored in database`,
          },
        },
        encrypt: {
          buttons: {
            copy: {
              color: 'primary',
              copiedTitle: 'Copied',
              title: 'Copy',
            },
            submit: {
              color: 'link',
              title: 'Submit',
            },
          },
          messageBox: {
            label: 'Encrypt',
            placeholder: 'Encrypt',
          },
          title: 'Encrypt',
          result: {
            label: 'Result',
            placeholder: 'Result',
          },
        },
      },
      recentTable: {
        title: 'Recent Results',
        columns: {
          text: { title: 'Text' },
          result: { title: 'Result' },
          createdAt: { title: 'Created At' },
        },
      },
    },
  },
  general: {
    basic: {
      title: 'My Hash',
    },
    header: {
      color: 'link',
      size: 'medium',
      subtitle: 'Encrypt & Decrypt Text Online',
      title: 'MyHash',
    },
    navbar: {
      image: {
        height: 28,
        link: '/img/logo/default.png',
        width: 112,
      },
    },
  },
  install: true,
}

export const defaultStats = {
  Ciphers: {
    AES: {
      decrypt: 0,
      encrypt: 0,
    },
    DES: {
      decrypt: 0,
      encrypt: 0,
    },
    Rabbit: {
      decrypt: 0,
      encrypt: 0,
    },
    TripleDES: {
      decrypt: 0,
      encrypt: 0,
    },
  },
  Encoders: {
    Base64: {
      decrypt: 0,
      encrypt: 0,
    },
    Hex: {
      decrypt: 0,
      encrypt: 0,
    },
    Latin1: {
      decrypt: 0,
      encrypt: 0,
    },
    Utf16: {
      decrypt: 0,
      encrypt: 0,
    },
    Utf16LE: {
      decrypt: 0,
      encrypt: 0,
    },
    Utf8: {
      decrypt: 0,
      encrypt: 0,
    },
  },
  Hash: {
    MD5: {
      decrypt: 0,
      encrypt: 0,
    },
    SHA1: {
      decrypt: 0,
      encrypt: 0,
    },
    SHA256: {
      decrypt: 0,
      encrypt: 0,
    },
    SHA3: {
      decrypt: 0,
      encrypt: 0,
    },
    SHA512: {
      decrypt: 0,
      encrypt: 0,
    },
  },
  Hmac: {
    HmacMD5: {
      decrypt: 0,
      encrypt: 0,
    },
    HmacSHA1: {
      decrypt: 0,
      encrypt: 0,
    },
    HmacSHA256: {
      decrypt: 0,
      encrypt: 0,
    },
    HmacSHA512: {
      decrypt: 0,
      encrypt: 0,
    },
  },
  PBKDF2: {
    PBKDF2: {
      decrypt: 0,
      encrypt: 0,
    },
  },
}
