import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { cryptoListName } from '@/utils/utils'

const cryptoList: string[] = cryptoListName()

function load(component: string) {
  return () => import(`../views/${component}.vue`)
}

const routes: Array<RouteRecordRaw> = cryptoList
  .map((crypto) => {
    const cryptoUrl = crypto.toLowerCase()
    return [
      {
        path: `/${cryptoUrl}`,
        component: load('_Crypto'),
        meta: { crypto },
      },
    ]
  })
  .flat()

const anotherRoutes: Array<RouteRecordRaw> = [
  { name: 'Home', path: '/', component: load('Home'), redirect: '/md5' },
  {
    name: 'Admin',
    path: '/admin',
    component: load('Admin'),
    children: [
      { path: '', component: load('Admin/Index') },
      { path: 'statistics', component: load('Admin/Statistics') },
      { path: 'config', redirect: 'config/general' },
      { path: 'config/general', component: load('Admin/Config') },
      { path: 'config/components', component: load('Admin/Config/Components') },
    ],
  },
]

anotherRoutes.forEach((route) => {
  routes.push(route)
})

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
