import StoreService from './Store'
import { auth } from '@/firebase'
import { getStartAndEndDateBy, cryptoList } from '@/utils/utils'

class AdminService {
  async login(email: string, password: string) {
    try {
      const admin = await auth.signInWithEmailAndPassword(email, password)
      return {
        success: true,
        data: admin,
      }
    } catch (err) {
      return {
        success: false,
        data: err.message,
      }
    }
  }

  async registerAdminAccount(email: string, password: string) {
    try {
      const admin = await auth.createUserWithEmailAndPassword(email, password)
      return {
        success: true,
        data: admin,
      }
    } catch (err) {
      return {
        success: false,
        data: err,
      }
    }
  }

  getAdminCredentials() {
    return auth.currentUser
  }

  async loadGeneralData() {
    const cryptoStats: any[] = []
    const cryptoTypeStats: any[] = []
    const generalData = await StoreService.getGeneralStatistics()
    const cryptoStatisticsData = generalData
    for (const cryptoType in generalData) {
      const typeStats = { type: cryptoType, encrypt: 0, decrypt: 0 }
      const cryptoList = generalData[cryptoType]
      for (const cryptoName in cryptoList) {
        const { encrypt, decrypt } = cryptoList[cryptoName]
        const stats = { name: cryptoName, encrypt, decrypt }
        typeStats.encrypt += encrypt
        typeStats.decrypt += decrypt
        cryptoStats.push(stats)
      }
      cryptoTypeStats.push(typeStats)
    }
    return {
      cryptoStatisticsData,
      cryptoTypeStats,
      cryptoStats,
    }
  }

  async getMonthlyLog() {
    const { startDate, endDate } = getStartAndEndDateBy('month')
    const logsSnap = await StoreService.getLogByDate(startDate, endDate)
    return logsSnap
  }

  async getMonthlyLogByCryptoType(logsSnap: any, cryptoType: string) {
    const cryptoNameList = cryptoList.filter((c) => c.type === cryptoType)[0]
      .list
    const data: any[] = []
    logsSnap.forEach((logSnap: any) => {
      const log = logSnap.val()
      if (cryptoNameList.includes(log.cryptoName)) {
        data.push(log)
      }
    })
    return data
  }

  async getConfig() {
    return await StoreService.getConfig()
  }
}

export default new AdminService()
