
import { defineComponent, ref } from 'vue'
import AdminService from '@/services/Admin'
import ConfigService from '@/services/Config'

export default defineComponent({
  setup() {
    const basicInformation = ref({
      appTitle: 'My Hash',
      appLogo: '/img/logo/default.png',
      appHeader: 'MyHash',
    })
    const adminAccount = ref({
      email: '',
      password: '',
    })
    const isInstalling = ref(false)
    const installingLogs = ref<Array<any>>([])
    const addContent = (content: string, type: string) => {
      installingLogs.value.push({ content, type })
    }
    const install = async (e: Event) => {
      e.preventDefault()
      isInstalling.value = true
      installingLogs.value = []
      const { email, password } = adminAccount.value
      addContent('Start installing the app', 'primary')
      addContent('Updating config...', 'primary')
      const installResponse = await ConfigService.installApp(
        basicInformation.value
      )
      if (installResponse.success) {
        addContent('Update config is complete', 'primary')
        addContent('Creating admin account...', 'primary')
        const registerResponse = await AdminService.registerAdminAccount(
          email,
          password
        )
        if (registerResponse.success) {
          addContent('Create admin account is complete', 'primary')
          addContent(
            'You will be redirected to the website within seconds.',
            'primary'
          )
          return (location.href = '/')
        }
        await ConfigService.removeConfig()
        isInstalling.value = false
        return addContent(`ERROR: ${registerResponse.data}`, 'danger')
      }
      await ConfigService.removeConfig()
      isInstalling.value = false
      addContent(`ERROR: ${installResponse.data}`, 'danger')
    }
    return {
      basicInformation,
      adminAccount,
      isInstalling,
      installingLogs,
      install,
    }
  },
})
