import { createStore } from 'vuex'
import { cryptoList, cryptoListName, defaultConfig } from '@/utils/utils'
import { Config } from '@/types/config'
import ConfigService from '@/services/Config'
import AdminService from '@/services/Admin'

export interface State {
  currentCryptoName: string
  cryptoList: Array<any>
  isSubmitting: boolean
  newCryptoEncrypted: any
  config: Config
  configStatus: string
  currentTab: string
  admin: any
}

export default createStore<State>({
  state: {
    currentCryptoName: 'MD5',
    cryptoList: cryptoList,
    isSubmitting: false,
    newCryptoEncrypted: null,
    config: defaultConfig,
    configStatus: 'default',
    currentTab: 'encrypt',
    admin: undefined,
  },
  mutations: {
    UPDATE_CURRENT_CRYPTO_TYPE(state, payload) {
      state.currentCryptoName = payload
    },
    UPDATE_IS_SUBMITTING(state, payload) {
      state.isSubmitting = payload
    },
    SET_NEW_CRYPTO_ENCRYPTED(state, payload) {
      state.newCryptoEncrypted = payload
    },
    SET_CONFIG(state, payload) {
      state.config = payload
    },
    SET_CONFIG_STATUS(state, payload) {
      state.configStatus = payload
    },
    SET_CURRENT_TAB(state, payload) {
      state.currentTab = payload
    },
    SET_ADMIN(state, payload) {
      state.admin = payload
    },
  },
  getters: {
    cryptoType(state) {
      let typeName
      state.cryptoList.forEach((crypto) => {
        if (crypto.list.includes(state.currentCryptoName)) {
          typeName = crypto.type
          return
        }
      })
      return typeName
    },
    cryptoListName: () => cryptoListName(),
  },
  actions: {
    async loadConfig({ commit }) {
      commit('SET_CONFIG_STATUS', 'loading')
      const config = await ConfigService.loadAppConfig()
      commit('SET_CONFIG', config)
      commit('SET_CONFIG_STATUS', 'loaded')
    },
    async updateConfig(_, { type, data }) {
      await ConfigService.updateGeneralConfig(type, data)
    },
    async updateComponentsConfig(_, { child, data }) {
      await ConfigService.updateComponentsConfig(child, data)
    },
    async login({ commit }, { email, password }) {
      const response = await AdminService.login(email, password)
      if (response.success) {
        commit('SET_ADMIN', response.data)
      }
      return response
    },
    loadAdminCredentials({ commit }) {
      const user = AdminService.getAdminCredentials()
      commit('SET_ADMIN', user)
    },
  },
})
