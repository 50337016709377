import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyA95jhMnk3iigQCbOUxt2cF0qDLi42E7hI",
  authDomain: "dprime-hash.firebaseapp.com",
  projectId: "dprime-hash",
  storageBucket: "dprime-hash.appspot.com",
  messagingSenderId: "651921077959",
  appId: "1:651921077959:web:b9c58969c6b730418af0e2"
};

firebase.initializeApp(firebaseConfig)

export const database = firebase.database()
export const auth = firebase.auth()
