
import { defineComponent, watch, ref, computed } from 'vue'
import { Config } from './types/config'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useHead } from '@vueuse/head'

import Navbar from '@/components/Navbar.vue'
import Header from '@/components/Header.vue'
import Sidebar from '@/components/Sidebar.vue'
import AdminSidebar from '@/components/AdminComponents/Sidebar.vue'
import Preloader from '@/components/Preloader.vue'
import Install from '@/components/Install.vue'

export default defineComponent({
  components: {
    Navbar,
    Header,
    Sidebar,
    AdminSidebar,
    Preloader,
    Install,
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const appConfig = computed(() => store.state.config as Config)
    const generalInfo = computed(() => appConfig.value?.general)
    const disablePreloader = ref(false)
    const isAppInstalled = ref(false)
    const isAdminPage = ref(false)
    watch(
      () => route.path,
      () => {
        isAdminPage.value = route.path.includes('admin')
      }
    )
    const loadConfig = async () => {
      await store.dispatch('loadConfig')
      if (appConfig.value?.install) {
        isAppInstalled.value = true
      }
      disablePreloader.value = true
    }
    const loadAdminCredentials = () => {
      store.dispatch('loadAdminCredentials')
    }
    loadConfig()
    loadAdminCredentials()
    useHead({
      title: computed(() => generalInfo.value?.basic.title),
      meta: computed(() =>
        generalInfo.value?.basic.meta ? generalInfo.value?.basic.meta : []
      ),
    })
    return {
      isAdminPage,
      generalInfo,
      disablePreloader,
      isAppInstalled,
    }
  },
})
