import StoreService from './Store'
import { Basic, Config, Header, Navbar } from '@/types/config'
import {
  defaultConfiguration,
  defaultStats,
} from '@/utils/default-configuration'

class ConfigService {
  async loadAppConfig() {
    const config = await StoreService.getConfig()
    return config.val() as Config
  }

  async updateGeneralConfig(type: string, data: Basic | Header | Navbar) {
    const child = `general/${type}`
    await StoreService.saveConfig(child, data)
  }

  async updateComponentsConfig(child: string, data: any) {
    await StoreService.saveConfig(child, data)
  }

  async installApp(basicInformation: any) {
    const { appTitle, appLogo, appHeader } = basicInformation
    const config = defaultConfiguration
    config.general.basic.title = appTitle
    config.general.navbar.image.link = appLogo
    config.general.header.title = appHeader
    try {
      await StoreService.save('config', config)
      await StoreService.save('stats', defaultStats)
      return {
        success: true,
        data: null,
      }
    } catch (err) {
      return {
        success: false,
        data: err,
      }
    }
  }

  async removeConfig() {
    await StoreService.save('config', null)
  }
}

export default new ConfigService()
