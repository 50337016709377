
import { computed, defineComponent } from 'vue'
import { Navbar } from '@/types/config'

import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    const store = useStore()
    const navbarInfo = computed(
      () => store.state.config.general.navbar as Navbar
    )
    const image = computed(() => navbarInfo.value.image)
    const items = computed(() => navbarInfo.value.items)
    return { image, items }
  },
})
