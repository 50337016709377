
import { defineComponent, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const currentCryptoName = computed(() => store.state.currentCryptoName)
    const updateCurrentSidebar = () => {
      const type = route.meta.crypto
      store.commit('UPDATE_CURRENT_CRYPTO_TYPE', type)
    }
    watch(
      () => route.path,
      () => updateCurrentSidebar()
    )
    updateCurrentSidebar()
    const goTo = (type: string) => router.push(`/${type.toLowerCase()}`)
    const cryptoList = store.state.cryptoList
    return {
      cryptoList,
      currentCryptoName,
      goTo,
    }
  },
})
