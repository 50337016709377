<template>
  <div>
    <transition leave-active-class="animate__animated animate__fadeOut">
      <div class="preloader" v-if="!disabled">
        <img src="@/assets/preloader.svg" alt="loader" />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    disabled: Boolean,
  },
  setup() {
    return {}
  },
})
</script>

<style>
.preloader {
  align-items: center;
  background: rgb(255, 255, 255);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.3s linear;
  width: 100%;
  z-index: 9999;
}
</style>
