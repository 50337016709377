import { database } from '@/firebase'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import CryptoJs from 'crypto-js'
import store from '@/store/index'

dayjs.extend(utc)

const cryptoRef = database.ref('crypto')
const statsRef = database.ref('stats')
const logRef = database.ref('logs')
const configRef = database.ref('config')

class StoreService {
  async addCryptoRecord(
    text: string,
    result: string,
    cryptoType: string,
    cryptoName: string,
    options = {}
  ) {
    const now = dayjs().utc().unix()
    const docId = CryptoJs.SHA1(result).toString()
    const data = {
      text,
      result,
      createdAt: now,
      options,
    }
    store.commit('SET_NEW_CRYPTO_ENCRYPTED', { text, result, createdAt: now })
    await cryptoRef.child(cryptoType).child(cryptoName).child(docId).set(data)
    await this.addEncryptAndDecryptStatistics(cryptoType, cryptoName, 'encrypt')
    this.addLog(cryptoName, 'encrypt', true)
  }

  async getRecentCryptoRecords(cryptoType: string, cryptoName: string) {
    const recentRecords = cryptoRef
      .child(cryptoType)
      .child(cryptoName)
      .orderByChild('createdAt')
      .limitToLast(10)

    return recentRecords
  }

  async getCryptoRecord(
    encryptedText: string,
    cryptoType: string,
    cryptoName: string
  ) {
    let success = false
    const records = await cryptoRef
      .child(cryptoType)
      .child(cryptoName)
      .orderByChild('result')
      .equalTo(encryptedText)
      .get()
    if (records.val()) {
      this.addEncryptAndDecryptStatistics(cryptoType, cryptoName, 'decrypt')
      success = true
    }
    this.addLog(cryptoName, 'decrypt', success)
    return records
  }

  async addEncryptAndDecryptStatistics(
    cryptoType: string,
    cryptoName: string,
    type: string
  ) {
    const cryptoStatsRef = statsRef.child(cryptoType).child(cryptoName)
    cryptoStatsRef.transaction((cryptoStats) => {
      if (cryptoStats) {
        cryptoStats[type]++
      }
      return cryptoStats
    })
    return type
  }

  async getGeneralStatistics() {
    const stats = await statsRef.get()
    return stats.val()
  }

  async getCryptoInThisMonth(cryptoType: string, cryptoName: string) {
    const startDate = dayjs().startOf('month').unix()
    const endDate = dayjs().endOf('month').unix()
    return await cryptoRef
      .child(cryptoType)
      .child(cryptoName)
      .orderByChild('createdAt')
      .startAt(startDate)
      .endAt(endDate)
      .get()
  }

  async addLog(cryptoName: string, type: string, success: boolean) {
    const now = dayjs().unix()
    logRef.push({
      cryptoName,
      type,
      createdAt: now,
      success,
    })
  }

  getLog(startDate: number, endDate: number) {
    return logRef.orderByChild('createdAt').startAt(startDate).endAt(endDate)
  }

  async getLogByDate(startDate: number, endDate: number) {
    return await this.getLog(startDate, endDate).get()
  }

  async getConfig() {
    return await configRef.get()
  }

  async saveConfig(child: string, data: any) {
    return await configRef.child(child).set(data)
  }

  async save(child: string, data: any) {
    return await database.ref(child).set(data)
  }
}

export default new StoreService()
