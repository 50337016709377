
import { defineComponent, ref, watch } from 'vue'
import { RouteLocationNormalizedLoaded, useRoute, useRouter } from 'vue-router'

const checkRouteMatched = (
  route: RouteLocationNormalizedLoaded,
  menuRoute: string
) => {
  if (route.path === '/admin' && menuRoute === '') return true
  return route.path === `/admin/${menuRoute}`
}

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const menu = ref([
      {
        title: 'Dashboard',
        routes: [
          { name: 'Dashboard', path: '', active: true },
          { name: 'Statistics', path: 'statistics', active: false },
        ],
      },
      {
        title: 'Config',
        routes: [
          { name: 'App', path: 'config/general', active: false },
          { name: 'Components', path: 'config/components', active: false },
        ],
      },
    ])
    const updateMenu = () => {
      menu.value = menu.value.map((menu) => {
        const list = menu.routes.map((menuRoute) => {
          if (checkRouteMatched(route, menuRoute.path)) {
            menuRoute.active = true
          } else {
            menuRoute.active = false
          }
          return menuRoute
        })
        return { ...menu, routes: list }
      })
    }
    watch(
      () => route.path,
      () => updateMenu()
    )
    const goTo = (path: string) => {
      router.push(`/admin/${path}`)
    }
    updateMenu()
    return {
      menu,
      goTo,
    }
  },
})
