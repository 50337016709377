<template>
  <div>
    <div class="modal is-active">
      <div class="modal-background"></div>
      <form class="modal-card" @submit="install">
        <header class="modal-card-head">
          <p class="modal-card-title">Installation</p>
        </header>
        <section class="modal-card-body">
          <div class="field">
            <p class="title is-4">Basic App Information (you can edit later)</p>
            <div class="field">
              <label class="label">App title</label>
              <div class="control">
                <input
                  type="text"
                  class="input"
                  placeholder="Enter the app name"
                  required
                  v-model="basicInformation.appTitle"
                />
              </div>
            </div>
            <div class="field">
              <label class="label">App header</label>
              <div class="control">
                <input
                  type="text"
                  class="input"
                  placeholder="Enter the app header"
                  required
                  v-model="basicInformation.appHeader"
                />
              </div>
            </div>
            <div class="field">
              <label class="label">App logo</label>
              <div class="control">
                <input
                  type="text"
                  class="input"
                  placeholder="Enter the app logo"
                  required
                  v-model="basicInformation.appLogo"
                />
              </div>
            </div>
            <hr />
            <p class="title is-4">Create your admin account</p>
            <div class="field">
              <label class="label">Admin Email</label>
              <div class="control">
                <input
                  type="email"
                  class="input"
                  required
                  v-model="adminAccount.email"
                />
              </div>
            </div>
            <div class="field">
              <label class="label">Admin Password</label>
              <div class="control">
                <input
                  type="password"
                  class="input"
                  required
                  v-model="adminAccount.password"
                />
              </div>
            </div>
          </div>
          <div
            v-for="(log, index) in installingLogs"
            :key="index"
            :class="`notification is-${log.type}`"
          >
            {{ log.content }}
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" :disabled="isInstalling">
            Install
          </button>
        </footer>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import AdminService from '@/services/Admin'
import ConfigService from '@/services/Config'

export default defineComponent({
  setup() {
    const basicInformation = ref({
      appTitle: 'My Hash',
      appLogo: '/img/logo/default.png',
      appHeader: 'MyHash',
    })
    const adminAccount = ref({
      email: '',
      password: '',
    })
    const isInstalling = ref(false)
    const installingLogs = ref<Array<any>>([])
    const addContent = (content: string, type: string) => {
      installingLogs.value.push({ content, type })
    }
    const install = async (e: Event) => {
      e.preventDefault()
      isInstalling.value = true
      installingLogs.value = []
      const { email, password } = adminAccount.value
      addContent('Start installing the app', 'primary')
      addContent('Updating config...', 'primary')
      const installResponse = await ConfigService.installApp(
        basicInformation.value
      )
      if (installResponse.success) {
        addContent('Update config is complete', 'primary')
        addContent('Creating admin account...', 'primary')
        const registerResponse = await AdminService.registerAdminAccount(
          email,
          password
        )
        if (registerResponse.success) {
          addContent('Create admin account is complete', 'primary')
          addContent(
            'You will be redirected to the website within seconds.',
            'primary'
          )
          return (location.href = '/')
        }
        await ConfigService.removeConfig()
        isInstalling.value = false
        return addContent(`ERROR: ${registerResponse.data}`, 'danger')
      }
      await ConfigService.removeConfig()
      isInstalling.value = false
      addContent(`ERROR: ${installResponse.data}`, 'danger')
    }
    return {
      basicInformation,
      adminAccount,
      isInstalling,
      installingLogs,
      install,
    }
  },
})
</script>
