<template>
  <section :class="sectionClass">
    <div class="hero-body has-text-centered">
      <p class="title is-1">{{ title }}</p>
      <p class="subtitle is-3 mt-3">{{ subtitle }}</p>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { Header } from '@/types/config'
import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    const store = useStore()
    const headerInfo = computed(
      () => store.state.config.general.header as Header
    )
    const title = computed(() => headerInfo.value.title)
    const subtitle = computed(() => headerInfo.value.subtitle)
    const sectionClass = computed(() => {
      const { size, color } = headerInfo.value
      return `hero is-${size} is-${color}`
    })
    return {
      title,
      subtitle,
      sectionClass,
    }
  },
})
</script>
