<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link class="navbar-item" to="/">
        <img :src="image.link" :width="image.width" :height="image.height" />
      </router-link>

      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarItems"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbarItems" class="navbar-menu">
      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <a
              :class="`button is-${item.color}`"
              v-for="(item, i) in items"
              :key="i"
              :href="item.link"
              target="_blank"
            >
              {{ item.title }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { Navbar } from '@/types/config'

import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    const store = useStore()
    const navbarInfo = computed(
      () => store.state.config.general.navbar as Navbar
    )
    const image = computed(() => navbarInfo.value.image)
    const items = computed(() => navbarInfo.value.items)
    return { image, items }
  },
})
</script>
