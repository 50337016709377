
import { defineComponent, computed } from 'vue'
import { Header } from '@/types/config'
import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    const store = useStore()
    const headerInfo = computed(
      () => store.state.config.general.header as Header
    )
    const title = computed(() => headerInfo.value.title)
    const subtitle = computed(() => headerInfo.value.subtitle)
    const sectionClass = computed(() => {
      const { size, color } = headerInfo.value
      return `hero is-${size} is-${color}`
    })
    return {
      title,
      subtitle,
      sectionClass,
    }
  },
})
